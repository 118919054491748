@import '@/_styles/import';
.root {
  display: flex;
  align-items: center;

  padding: 8px;

  color: $black-violet;

  background-color: $light-green1;
  border-radius: 8px;
}

.applied {
  padding-bottom: 16px;

  color: inherit;

  background-color: transparent;
  border-bottom: 3px solid rgba($lightwhite, 0.2);
  border-radius: 0;

  .description {
    border-color: rgba($lightwhite, 0.2);
  }

  .button {
    color: inherit;
  }
}

.description {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: flex-start;

  border-right: 1px solid rgba($black-violet, 0.2);
}

.heading {
  margin-bottom: 4px;
  padding: 1px 4px;

  font-size: rem(13);
  font-weight: 500;
  line-height: rem(20);

  background: rgba($black-violet, 0.05);
  border-radius: 4px;
}

.text {
  margin: 0;
  padding: 0;

  font-size: rem(14);
  font-weight: 400;
  line-height: rem(20);
}

.button {
  flex-shrink: 0;

  padding: 6px 16px;

  font-size: rem(15);
  font-weight: 500;
  line-height: 132%;
  color: $black;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0.225px;

  &::after {
    display: none;
  }
}
