@import '@/_styles/import';
.root {
  --tooltip-outline-color: #{$white};
  --tooltip-outline-offset: 1px;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  margin: 0 auto;
  padding: 24px 20px;

  color: $white;

  background: $black-violet;
  border-radius: 4px;

  :global(.loading-skeleton) {
    --base-color: #{$black-violet};
    --highlight-color: #{$purple-02};
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 16px;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;

  height: 20px;
  margin-bottom: 16px;

  color: $gray-010;
}

// TODO: css loaded wrong by next
.trigger.trigger {
  text-align: right;
  text-decoration: underline;
}

.title {
  @include font($size: rem(14), $weight: 400, $transform: uppercase, $spacing: 1px);

  margin: 0;
}

// TODO: css loaded wrong by next
.more.more {
  @include font($size: rem(14), $color: $gray-010, $decoration: underline, $align: right);

  &:focus-visible {
    outline-color: $white;
  }

  .root:global(.skeleton) & {
    @include reset-button();

    cursor: default;
    text-decoration: none;
    opacity: 0.5;
  }
}

.perk,
.discount,
.summary {
  margin-bottom: 16px;
}

.total {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  padding: 16px 0;

  border-top: 3px solid rgba($lightwhite, 0.2);
}

.pricingHeading {
  @include font($size: rem(20), $weight: 700, $line-height: rem(28));

  padding-right: 10px;
}

.priceContainer {
  display: grid;
  text-align: right;

  .priceValue {
    :global {
      .price {
        display: grid;
      }

      .original-amount {
        @include font($size: rem(15), $line-height: rem(24), $color: $gray-010);
      }

      .amount {
        --discount-color: #{$light-orange};

        @include font($size: rem(20), $weight: 700, $line-height: rem(28));
      }
    }
  }

  .uplift {
    color: $gray-010;
  }
}

.upliftIcon {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;

  width: 1rem;
  height: 1rem;
  margin-left: 5px;

  line-height: 1rem;
  color: $black-violet;

  background-color: $white;
  border-radius: 50%;

  &:focus-visible {
    outline-color: var(--tooltip-outline-color);
    outline-offset: var(--tooltip-outline-offset);
  }
}

.duePayment {
  @include font($size: rem(14), $weight: 500, $line-height: rem(20));

  padding: 16px 0;
  border-top: 3px solid rgba($lightwhite, 0.2);
}
