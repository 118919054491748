@import '@/_styles/import';
.infoLink {
  padding: 0 3px;

  font-size: 15px;
  font-weight: 400;
  line-height: 1.6em;
  color: $black;
  text-decoration: underline;

  background: none;
  border: 0;
}

.overlay {
  backdrop-filter: blur(8px);
}

.modal {
  width: 325px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $black-001;

  @include media-breakpoint-up(lg) {
    width: 420px;
  }

  @include media-breakpoint-up(xxl) {
    width: 460px;
  }
}

.container {
  padding: 60px 20px 20px;

  @include media-breakpoint-up(lg) {
    padding: 60px 40px 30px;
  }

  @include media-breakpoint-up(xxl) {
    padding: 80px 60px 40px;
  }
}

.closeButton.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  left: auto;

  width: 30px;
  height: 30px;
}

.describeSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.32em;
  color: $black;
  text-align: center;
  text-wrap: pretty;

  @include media-breakpoint-up(xxl) {
    font-size: 30px;
  }
}

.description {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.64em;
  color: $black;
  text-align: center;
  text-wrap: pretty;
}

.termsLink {
  margin-top: 30px;
  padding: 15px 20px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin-top: 40px;
  }

  @include media-breakpoint-up(xxl) {
    margin-top: 50px;
  }

  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25em;
    color: $lightgrey2;
    text-decoration: underline;
    letter-spacing: 0.24px;
  }
}
