@import '@/_styles/import';
.root {
  position: relative;
  margin-bottom: 20px;

  &::after {
    content: '';

    position: absolute;
    bottom: -5px;
    left: calc(50% - 1px);

    display: none;

    width: 1px;
    height: 27px;
    margin: 0 auto;

    background-color: $gray-510;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  @media (min-resolution: $zoom-more-than-250) {
    @include media-breakpoint-down(sm) {
      margin-bottom: 80px;
    }
  }
}

.icon {
  display: block;
  width: 71px;
  height: 71px;
  margin: 40px auto 8px;
}

.title {
  margin-bottom: 0;

  @include font($size: rem(15), $weight: normal, $line-height: rem(24), $color: $gray-510, $align: center);
}

.description {
  @include font($size: rem(17), $weight: 500, $line-height: rem(28), $color: $grey-text, $align: center);

  max-width: 237px;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    max-width: 630px;
    padding-bottom: 42px;
  }
}
