@import '@/_styles/import';
.container {
  padding: 16px 0;
  border-top: 3px solid rgba($lightwhite, 0.2);
}

// TODO: css loaded wrong by next
.trigger.trigger {
  @include font($size: rem(15), $color: $grey-light);

  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center flex-start;
  width: 100%;

  &:focus-visible {
    outline-color: $white;
  }

  .close {
    justify-self: flex-end;

    width: 24px;
    height: 24px;

    color: $black;

    background-color: $gray-216;
    border-radius: 50%;
  }

  .chevron {
    color: $light-orange;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 16px 2px 2px;

  .row {
    display: flex;
    gap: 10px;
  }
}

.input {
  width: 100%;
  max-width: 300px;
  padding: 8px;

  border: 1px solid #ddd;
  border-radius: 4px;

  &[data-invalid] {
    color: $error;
    border-color: $error;

    &::placeholder {
      color: $error;
    }
  }
}

.errorMessage {
  color: $error-dark;
}

.submitButton {
  @include font($size: rem(12), $weight: 700, $color: $white, $spacing: 0.2em);

  cursor: pointer;

  min-width: 100px;

  background: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;

  transition:
    200ms background-color,
    200ms color;

  &:focus-visible {
    border-color: $black;
    outline-color: $white;
  }

  &:hover {
    color: $black;
    background-color: $white;
  }

  &:disabled {
    pointer-events: none;
    color: $gray-010;
    background-color: $lightwhite;
  }
}

.applied {
  @include font($weight: bold, $color: $light-green1);

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 5px;
  align-items: center;

  .clear {
    padding: 5px;

    &:focus-visible {
      outline-color: $white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    :global(.Icon) {
      width: 20px;
      height: 20px;
      fill: $white;
    }
  }
}
