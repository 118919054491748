@import '@/_styles/import';
.container {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 28px;
  }

  .row {
    display: flex;
    gap: 16px;
    justify-content: center;

    &.regular {
      @include media-breakpoint-down(sm) {
        gap: 10px;
      }
    }
  }
}
